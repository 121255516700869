body {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.main-container {
  width: 100%;
 height: 100vh;
 position: relative;
 background-image: radial-gradient(circle at 64% 7%, rgba(55, 55, 55,0.06) 0%, rgba(55, 55, 55,0.06) 50%,rgba(228, 228, 228,0.06) 50%, rgba(228, 228, 228,0.06) 100%),radial-gradient(circle at 71% 6%, rgba(182, 182, 182,0.06) 0%, rgba(182, 182, 182,0.06) 50%,rgba(86, 86, 86,0.06) 50%, rgba(86, 86, 86,0.06) 100%),radial-gradient(circle at 94% 76%, rgba(158, 158, 158,0.06) 0%, rgba(158, 158, 158,0.06) 50%,rgba(76, 76, 76,0.06) 50%, #4c4c4c0f 100%),linear-gradient(90deg, #fafafa,#dcdcdc);
}


/* start of top-navbar */

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  background-color: #212738;
  height: 80px;
}

 .navbar-items .nav-link {
  color: #ffffff;
  padding: 0;
}

.sign-in  {
  margin-left: auto;
}

.navbar-items .nav-user {
  background-color: transparent;
  border: 0;
  padding: 0;
}

/* end of top-navbar */


/* start of signin-page */
.signin-page  {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.input-field,
.line-break {
  margin: 5% auto;
}

.input-field .form-control {
  background-color: white;
  border: white;
  color: #212738;
}

.login-title {
  color: #212738;
  text-align: left;
}

.sigin-btn,
.gitlab-btn {
  width: 180px;
  background-color: #212738;
  border: 0;
}

.sigin-btn:disabled {
  background-color: #212738df;
}

.sigin-btn:not(:disabled):not(.disabled):active
.gitlab-btn:not(:disabled):not(.disabled):active,
.sigin-btn:not(:disabled):not(.disabled):active:focus,
.gitlab-btn:not(:disabled):not(.disabled):active:focus,
.sigin-btn:hover,.gitlab-btn:hover {
  background-color: #3e4353;
  box-shadow: none;
  border: 0;
}

.line-break {
  font-size: 1.5rem;
}

.error-message  {
  color: #d60000ed;
  margin-top: 20px;
}

/* end of signin-page */

/* start of site content */

.site-content {
  position: absolute;
  width: 100%;
  height: 50%;
  transform: translateY(-50%);
  top: 53%;
  height: 77%;
  overflow-y: auto;
  background-color: #ffffff47;
  padding: 15px;
}


.site-content .table {
  color: #212738;
}

.site-content p   {
  color: #212738;
  font-size: 18px;
}

/* end of site content */

/* start of footer */
.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: auto;
}
/* end of footer */



@media (min-width: 992px) {
  body[data-layout="detached"] .container-fluid {
    max-width: 100%;
  }
}
